.module-filter {
  .filter {
    &-options {
      &-item {
          a {
            &.ui-slider-handle {
              background: $color-background;
              border:3px solid $color-secondary;
            }
          &.am_shopby_link_selected {
            color:$color-tertiary;
            border-left : 2px solid $color-tertiary;
            .label ,.count {
              color:$color-tertiary !important;
            }
          }
            &:hover {
              color: $color-tertiary !important;
              .label ,.count {
                color:$color-tertiary !important;
              }
            }
        }
      }
    }
  }
  .am-slider {
    &.ui-slider-horizontal {
      .ui-slider-range {
        background: $color-secondary;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .module-filter {
    .filter-title {
      strong {
        &:active {
          background-color: $color-tertiary !important;
          -webkit-box-shadow: 0 0 0 0.2rem $color-tertiary !important;
          box-shadow: 0 0 0 0.2rem $color-tertiary !important;
        }
      }
    }
  }
}