.footer .module-footer {
    color: $color-white ;

    .custom-checkbox .custom-control-description a {
        color: $color-white ;
    }
    .module-inner .footer__logo{
        max-height: 45px;
    }
}
